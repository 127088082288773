<template>
  <div>
    <!-- 这里放上中下三层的 中 。然后通过test1和test2组件将这里填充，并且需要在home的children属性里添加这几个选项，这样这里的router-view才是home下的routerview -->
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="this.$router.currentRoute.path!='/home'"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality' }">数据质量</el-breadcrumb-item>

      <!--<el-breadcrumb-item v-for="(item, index) in this.$route.meta" :key="index">
                            {{item.name}}
                        </el-breadcrumb-item>-->

    </el-breadcrumb>
    <!-- 页面主体区域 -->
    <el-main>
      <el-row>
        <span
          v-if="setting.advancesearch"
          style="font-size: 10px;"
        >搜索：</span>
        <el-select
          v-if="setting.advancesearch"
          v-model="value"
          placeholder="请选择项目"
        >
          <el-option
            v-for="item in coaloptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-row>
      <br />
      <span style="font-size: 12px;">王家岭项目概述：</span>
      <div
        class="coalblock"
        v-for="item in options"
        :key="item.title"
      >
        <span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>
        <br />
        <span style="font-size: 24px;">{{item.num}}</span>

      </div>

      <!-- <div class="coalblock">
                <span style="font-size: 12px; color:#9196a1 ;">子系统个数</span>
                </br>
                <span style="font-size: 24px;">14个</span>
            </div>
            <div class="coalblock">
                <span style="font-size: 12px; color:#9196a1 ;">子系统数据量</span>
                </br>
                <span style="font-size: 24px;">25TB</span>
            </div> -->
      <el-card>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          stripe
        >

          <el-table-column
            prop="system"
            label="系统"
            header-align="center"
            align="center"
            width="180"
          >

          </el-table-column>

          <el-table-column
            prop="subSystemCount"
            label="子系统个数"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="deviceNum"
            label="设备个数"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="创建时间"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="coalwatch(scope.row)"
              >查看</el-button> <!-- 这里要改，当子系统个数为0时，查看按钮不可用 -->
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>

        </el-table>

        <!-- 分页区域
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[1, 2, 5, 10]" :page-size="1" layout="total, sizes, prev, pager, next, jumper" :total="4">
                </el-pagination>  -->

        <div style="display: flex;justify-content: flex-end">
          <el-pagination
            background
            @current-change="currentChange"
            @size-change="sizeChange"
            :current-page="page"
            :page-size="size"
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>

    </el-main>
    <!-- <checkDataquality v-if="checkVisible" ref="checkquality"></checkDataquality> -->
  </div>
</template>

<script>
import checkDataquality from "./checkDataquality";
export default {
  name: "dataQuality",
  data() {
    return {
      coaloptions: [
        {
          value: "选项1",
          label: "王家岭项目",
        },
        {
          value: "选项2",
          label: "张家岭项目",
        },
        {
          value: "选项3",
          label: "巴拉素项目",
        },
        {
          value: "选项4",
          label: "xxx项目",
        },
      ],
      value: "",
      tableData: [
        {
          system: "主运输系统",
          subSystemCount: 3,
          createDate: "12h",
          deviceNum: 3,
          remark: "无",
        },
        {
          system: "通风系统",
          subSystemCount: 5,
          createDate: "12h",
          deviceNum: 4,
          remark: "无",
        },
        {
          system: "主排水系统",
          subSystemCount: 2,
          createDate: "12h",
          deviceNum: 8,
          remark: "无",
        },
        {
          system: "安全检测",
          subSystemCount: 6,
          createDate: "12h",
          deviceNum: 9,
          remark: "无",
        },
      ],
      options: [
        {
          title: "系统个数",
          num: "14个",
        },
        {
          title: "系统数据总量",
          num: "25TB",
        },
        {
          title: "hbase表",
          num: "186个",
        },
        {
          title: "hive表",
          num: "157",
        },
        {
          title: "influxdb表",
          num: "206",
        },
      ],
      page: 1, //当前第几页
      size: 10, //当前每页个数
      total: 100, //全部数据行数
      checkVisible: false,
      setting: {
        batchRemove: true,
        advancesearch: true,
      },
      name: "数据质量",
    };
  },
  mounted() {
    this.initData();

    //根据名字查询系统设置中的结果
    this.getRequest(
      "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        // console.log(this.name)
        // console.log(resp.data)
        this.setting.batchRemove = resp.data.batchremove;
        this.setting.advancesearch = resp.data.advancesearch;
        this.size = resp.data.numberperpage;
      }
    });
  },
  methods: {
    // 监听 pagesize 改变的事件
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    // 查看项目质量
    // coalwatch(id) {
    //     console.log('123');
    //     this.checkVisible = true;
    //     this.$nextTick(() => {
    //         this.$refs.checkquality.init(id);
    //     });
    // },
    coalwatch(system) {
      window.sessionStorage.setItem("dataQualitySystemId", system.id); //存到sessionStorage中，下一个页面获取
      this.$router.push({
        path: "/checkDataquality",
      });
    },
    initData() {
      this.getRequest(
        "/dataQuality/getDataQualityHomePageInfo/?page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data;
          this.total = resp.data.size;
        }
      });
    },
  },
  // components: {
  //     checkDataquality
  // }
};
</script>

<style scoped>
.el-select {
  width: 100px;
  margin-bottom: 10px;
}
</style>
